<template>
  <div class="user-wapper">
    <!-- <span class="title hidden-sm-and-up">{{ $t("个人信息") }}</span> -->
    <el-avatar  class="avatar" :src="$store.state.userInfo.head_img"></el-avatar>
    <div class="txt-wapper">
      <div class="uid">ID：{{ $store.state.userInfo.id }}</div>
      <div class="user-name">{{$store.state.userInfo.real_name?$store.state.userInfo.real_name:$t('未设置')}}</div>
    </div>
    <el-button @click="change" class="button_fullwidth button_blue">{{ $t("修改") }}</el-button>
  </div>
</template>

<script>
export default {
  methods:{
    change(){
      this.$emit('change')
    }
  }
}
</script>

<style scoped>
.user-wapper{
  background: #161f2c;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 33px 24px 23px;
  position: relative;
}
.uid{
  color: #91AAD1;
  font-weight: bold;
  margin: 8px 0 16px;
}
.user-name{
  color: #91AAD1;
  font-weight: bold;
  margin-bottom: 16px;
}
.title{
  position: absolute;
  top: 13px;
  left: 13px;
  font-weight: bold;
  color: #91AAD1;
}
.avatar{
  width: 90px;
  height: 90px;
}
.txt-wapper{
  text-align: center;
}
@media screen and (max-width:768px) {
  .user-wapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 24px;
  }
  .user-name{
    margin: 0;
  }
  .txt-wapper{
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    text-align: left;
  }
  .uid{
    margin-top: 4px;
    margin-bottom: 0;
  }
  .avatar{
    width: 48px;
    height: 48px;
    margin-right: 11px;
  }
  .button_fullwidth{
    width: auto !important;
    display: inline-block;
    min-width: 90px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>